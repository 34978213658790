import React from 'react'
import styled from 'styled-components'

const SectionGroup = styled.div`
  /* background: url(${props => props.image});
  background-size: cover; */
  background-color: white;
  display: grid;
  grid-gap: 20px;
  position:relative;
  padding-top:10px;
  color:#293A7E;

`

const SectionTtitleGroup = styled.div`
  display: grid;
  width: 80%;
  background-color: white;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`
const SectionTitle = styled.h3`
  font-size: 60px;
  line-height: 1.2;
  color: #293a7e;
  margin: 20px auto;
  text-align: center;

  @media (max-width: 720px) {
    font-size: 40px;
  }
`
const SectionText = styled.p`
  color: #293a7e;
  margin: 10px auto;
  text-align: center;
`

const SectionAbout = props => (
  <SectionGroup>
    <SectionTtitleGroup>
      <SectionTitle> {props.title} </SectionTitle>
      <SectionText>{props.text}</SectionText>
      <SectionText>{props.textmore}</SectionText>
    </SectionTtitleGroup>
  </SectionGroup>
)

export default SectionAbout
