import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'
import SectionAbout from '../components/sectionabout'

const AboutPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <div className="Hero">
      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="about" />
        </h1>
      </div>
    </div>

    <SectionAbout
      title={<FormattedMessage id="ourstory" />}
      text={<FormattedMessage id="ourstorytext" />}
    />

    <SectionAbout
      title={<FormattedMessage id="ourhotel" />}
      text={<FormattedMessage id="ourhoteltext" />}
      textmore={<FormattedMessage id="ourhoteltextmore" />}
    />
  </Layout>
)

AboutPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default AboutPage
